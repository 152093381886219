import React, { useState } from "react";
import SideBar from "../../dashboard/SideBar";
import FirstNavbar from "../../dashboard/FirstNavbar";
import {
  Card,
  Row,
  Col,
  Figure,
  Table,
  Button,
  Modal,
  Form,
} from "react-bootstrap";
import axios from "axios";
import { useEffect } from "react";
import { toast } from "react-toastify";

const image = require("../../../assets/imagesCustomer/image.png");

function AddBrochure() {
  const [jewelleryModal, setJewelleryModal] = useState(false);
  const [coinModal, setCoinModal] = useState(false);
  const [selection, setSelection] = useState("1");

  const handleCloseModal = () => setJewelleryModal(false);
  const handleShowModal = () => setJewelleryModal(true);

  const handleCloseModal1 = () => setCoinModal(false);
  const handleShowModal1 = () => setCoinModal(true);

  const onGoldSelect = (e) => {
    setSelection(e.target.value);
  };

  const [file, setFile] = useState("");
  const [title, setTitle] = useState("");
  console.log(title, "title==>");
  console.log(file, "file ==>");

  const [shopId] = useState(localStorage.getItem("shopId"));

  const addBrochure = async (editDetails) => {
    const formData = new FormData();
    formData.append('file', file)
    formData.append('title', title)
    const token = localStorage.getItem("accessToken");

    const data = await axios
      .post(
        `https://zelt-product.moshimoshi.cloud/shop/brochure/${shopId}`,

        formData,

        {
          headers: {
            "x-access-token": token,
            'content-type': 'multipart/form-data'
          },
        }
      )
      .catch((error) => {
        console.log("error ==>", error);
      });
    if (data.status == 200) {
      toast.success('Brochrue Successfully added')
      getBrochure();
    }
    else {
      toast.error('Something went wrong')
    }
    console.log("brochure added ===>", data);
  };

  const [brochureList, setBrochureList] = useState([]);
  const getBrochure = async () => {
    const data = await axios.get(
      `https://zelt-product.moshimoshi.cloud/shop/list/${shopId}`
    );

    setBrochureList(data?.data?.data?.brochures);
  };
  useEffect(() => {
    getBrochure();
  }, [shopId]);

  console.log(brochureList, "brochureListbrochureListbrochureList");
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }
  return (
    <div>
      <div class="sidebar">
        <SideBar />
      </div>
      <div class="content">
        <div className="container">
          <FirstNavbar />
          <h3 className="headertext">Brochures</h3>
          <div>
            <Card className="p-2">
              <Row>
                <Col md={4}>
                  <h3 className="headertext">Manage Brochures</h3>
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <Button variant="warning" onClick={handleShowModal}>
                    Upload new Brochures
                  </Button>
                </Col>
              </Row>
              <hr />
              <Card className="p-2">
                <h3 className="text1">Available Brochures</h3>

                <div className="row mt-3">
                  {brochureList
                    ? brochureList.map((ele) => (
                      <div className="col-md-4 ">
                        <div className="row border p-3 my-2  mx-2">
                          <div className="col-md-6">
                            <p className="m-auto mt-1"> {ele.title}</p>
                          </div>
                          <div className="col-md-6">
                            <a
                              href={ele.file}
                              download
                              target="_blank"
                              className="downloadPdf btn btn-danger text-white"
                            >
                              download
                            </a>
                          </div>
                        </div>
                        <p></p>
                      </div>
                    ))
                    : "  "}
                </div>
              </Card>
            </Card>
          </div>
        </div>
      </div>
      <Modal
        show={jewelleryModal}
        onHide={handleCloseModal}
        backdrop="static"
        keyboard={false}
        centered
        size="lg"
      >
        <h4 className="headertext text-center">Upload Brochure</h4>
        <Modal.Body>
          <Row>
            <Col>
              <Form.Group className="w-50">
                <Form.Label>Add Brochure PDF</Form.Label>
                <Form.Control
                  type="file"
                  placeholder="Brochure PDF"
                  className="mb-3"
                  name="file"
                  onChange={(e) => {
                    console.log("FILE---->", e.target.files[0])
                    setFile(e.target.files[0])
                  }}
                  autoComplete="off"
                />
                <Form.Control
                  type="text"
                  placeholder="Brochure Title"
                  className="mb-3"
                  name="title"
                  onChange={(e) => setTitle(e.target.value)}
                  autoComplete="off"
                />
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleCloseModal} variant="secondary">
            Cancel
          </Button>
          <Button variant="warning" onClick={addBrochure}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={coinModal}
        onHide={handleCloseModal1}
        backdrop="static"
        keyboard={false}
        centered
        size="lg"
      >
        <h4 className="headertext text-center">
          Brochure of Sri Vinayaka Jewellers
        </h4>
        <Modal.Body></Modal.Body>
        <Modal.Footer>
          <Button onClick={handleCloseModal1} variant="secondary">
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default AddBrochure;