import React from 'react'
import { useState } from "react";

import { ToastContainer, toast, Zoom } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import { Spinner } from "react-bootstrap";
import logozelt from './../../assets/imagesCustomer/logozelt.png'
import axios from 'axios';
import { Link } from 'react-router-dom';


function ForgotPassword() {
    const navigate = useNavigate();

    const [page, setPage] = useState(1)

    const [loader, setLoader] = useState(false)

    const [loginForm, setLoginForm] = useState({
        phone: '',

    })

    const [loginFormErrors, setLoginFormErrors] = useState({
        phone: '',
    })

    const [otpData, setOtpData] = useState({
        otp: '',
    })

    const [otpDataError, setOtpDataError] = useState({
        otp: '',
    })

    const [password, setPassword] = useState({
        password: '',
        confirmPassword: '',
    })

    const [passwordError, setPasswordError] = useState({
        password: '',
        confirmPassword: '',
    })


    const handleChangePhone = (e) => {
        const { name, value } = e.target;
        const numericValue = value.replace(/\D/g, ''); // Remove non-numeric characters

        // Check if the numericValue is 10 digits or less
        if (numericValue.length <= 10) {
            setLoginForm((prevData) => ({
                ...prevData,
                [name]: numericValue,
            }));
        }
    };

    const handleOTPChange = (e) => {
        const { name, value } = e.target;
        const numericValue = value.replace(/\D/g, ''); // Remove non-numeric characters

        // Check if the numericValue is 4  digits or less
        if (numericValue.length <= 4) {
            setOtpData((prevData) => ({
                ...prevData,
                [name]: numericValue,
            }));
        }
    };

    // const handlePasswordChange = (e) => {
    //     const { name, value } = e.target;

    //     setPassword((prevData) => ({
    //         ...prevData,
    //         [name]: value,
    //     }));
    // };

    const handlePasswordChange = (event) => {
        setPassword({
            ...password, [event.target.name]: event.target.value
        })
    }

    const handleValidation = () => {
        const newErrors = {}

        if (loginForm.phone == '' && loginForm.phone >= 10) {
            newErrors.phone = 'Please Enter Your Registered Number'
        }
        return newErrors
    }

    const handleOtpValidation = () => {
        const newErrors = {}

        if (otpData.otp == '' && otpData.otp >= 4) {
            newErrors.otp = 'Please Enter The Otp Sent of Your Registered Number'
        }
        return newErrors
    }

    const handlePasswordValidation = () => {
        const newErrors = {}
        console.log('nknanda');

        // if (password.password != password.confirmPassword) {
        //     newErrors.password = 'Please Enter Correct Password'
        // }
        if (password.password == '' && password.password == password.confirmPassword) {
            newErrors.password = 'Please Enter Correct Password'
        }
        if (password.confirmPassword == '') {
            newErrors.confirmPassword = 'Please ReEnter Correct Password'
        }
        return newErrors
    }

    // eugia$#@!345
    const handleSentOtp = () => {
        const handleValidationObject = handleOtpValidation()
        if (Object.keys(handleValidationObject).length > 0) {
            setLoginFormErrors(handleValidationObject)
        } else {
            setLoader(true)
            const requestOptions = {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json'
                },
            };
            axios.put(`https://zelt-auth.moshimoshi.cloud/send-otp?phone=${loginForm?.phone}&type=changePassword`)
                .then((response) => {
                    console.log('response', response);
                    console.log("SHOP--->", response?.data?.data?.shops)
                    if (response?.status === 200) {

                        toast.success('OTP Sent Successfully')
                        setLoader(false)
                        setPage(2)
                    } 
                    else {
                        toast.error('user credentials are invalid')
                        setTimeout(() => {
                            setLoader(false)
                        }, [1000])
                    }
                })
                .catch((error) => {
                    console.log('error ==>', error);
                    setLoginForm({
                        ...loginForm,
                        phone: '',
                        password: ''
                    })
                    setLoader(false)
                })
            // setPage(2)
        }
    }

    const handleVerifyOtp = () => {
        const handleValidationObject = handleOtpValidation()
        if (Object.keys(handleValidationObject).length > 0) {
            setOtpDataError(handleValidationObject)
        } else {
            setLoader(true)
            const requestOptions = {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json'
                },
            };
            axios.put(`https://zelt-auth.moshimoshi.cloud/verify-otp?phone=${loginForm.phone}&otp=${otpData.otp}&type=changePassword`)
                .then((response) => {
                    if (response?.status === 200) {
                        toast.success('Verified Successfully')
                        setLoader(false)
                        setPage(3)
                    } else {
                        toast.error('user credentials are invalid')
                        setTimeout(() => {
                            setLoader(false)
                        }, [1000])
                    }
                })
                .catch((error) => {
                    console.log('error ==>', error);
                    toast.error('Something Went Wrong')
                    setLoader(false)
                })
        }
    }

    // const handleSubmit = () => {
    //     const handleValidationObject = handlePasswordValidation()
    //     if (Object.keys(handleValidationObject).length > 0) {
    //         setPasswordError(handleValidationObject)
    //     } else {
    //         setLoader(true)
    //         let userCredentials = { 'phone': loginForm.phone, 'password': password.confirmPassword }
    //         const requestOptions = {
    //             method: 'PUT',
    //             headers: {
    //                 'Accept': 'application/json, text/plain, */*',
    //                 'Content-Type': 'application/json'
    //             },
    //             body: JSON.stringify(userCredentials)
    //         };
    //         axios.put('https://zelt-auth.moshimoshi.cloud//user/change-password', requestOptions)
    //             .then(response => {
    //                 if (response.status == 200) {
    //                     toast.success('Login Successfully')
    //                     setLoader(false)
    //                     setLoginForm({
    //                         ...loginForm,
    //                         phone: '',
    //                     })
    //                     setTimeout(() => {
    //                         navigate('/');
    //                     }, 1000);

    //                 } else {
    //                     toast.error('user credentials are invalid')
    //                     setTimeout(() => {
    //                         setLoader(false)
    //                     }, [1000])
    //                 }
    //             }
    //             ).catch((err) => {
    //                 setLoginForm({
    //                     ...loginForm,
    //                     phone: '',
    //                     password: ''
    //                 })
    //             })
    //     }
    // }

    const handleSubmit = () => {
        const handleValidationObject = handlePasswordValidation()
        if (Object.keys(handleValidationObject).length > 0) {
            setPasswordError(handleValidationObject)
        } else {
            setLoader(true)
            let userCredentials = { 'phone': loginForm.phone, 'password': password.confirmPassword}
            const requestOptions = {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(userCredentials)
            };
            axios.put('https://zelt-auth.moshimoshi.cloud//user/change-password', userCredentials, { "headers": requestOptions })
                .then((response) => {

                    if (response?.status === 200) {

                        toast.success('Password Reset Done Successfully')
                        setLoader(false)

                        setTimeout(() => {
                            navigate('/');
                        }, 1000);

                    } else {
                        toast.error('user credentials are invalid')
                        setTimeout(() => {
                            setLoader(false)
                        }, [1000])
                    }
                })
                .catch((error) => {
                   
                    toast.warning(`${error.response.data.message}`, error);
                    
                    setLoader(false)
                })
            // fetch('https://zelt-auth.moshimoshi.cloud/user/login', requestOptions)
            //   .then(response => {    
            //     if (response.status == 400) {
            //       toast.error('user credentials are invalid')
            //       setTimeout(() => {
            //         setLoader(false)
            //       }, [1000])
            //     } else {
            //       console.log('response', response);
            //       console.log('response', response?.data);
            //       console.log('responsejson', JSON.stringify(response));
            //       console.log('responsejson',response.headers.get('data'));
            //       localStorage.setItem('accessToken', response.headers.get('x-access-token'));
            //       localStorage.setItem('refreshToken',response.headers.get('x-refresh-token'))
            //       // localStorage.setItem('userDetails',JSON.stringify(response?.data))
            //       toast.success('Login Successfully')
            //       setLoader(false)
            //       setLoginForm({
            //         ...loginForm,
            //         phone: '',
            //         password: ''
            //       })
            //       setTimeout(() => {
            //         navigate('/dashboard');
            //       }, 1000);
            //     }
            //   }
            //   ).catch((err) => {
            //     setLoginForm({
            //       ...loginForm,
            //       phone: '',
            //       password: ''
            //     })
            //   })
        }
    }



    return (
        <div>
            {" "}
            <div>
                <ToastContainer
                    position="top-center"
                    autoClose={1000}
                    hideProgressBar
                    transition={Zoom}
                    delay={500}
                    limit={1}
                />
                <div class="login">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <img src={logozelt} alt="" />
                        </div>
                    </div>
                    {page === 1 && <div>
                        <div>
                            <h2 class="login-header" style={{ marginTop: 20 }}>Reset Password </h2>
                            <p>
                                <input
                                    type="phone"
                                    placeholder="Phone Number"
                                    name='phone'
                                    autoComplete="off"
                                    value={loginForm.phone}
                                    onChange={handleChangePhone}
                                />
                                <span className="text-danger" >{loginFormErrors.phone}</span>
                            </p>
                            <Link to='/'>Login ?</Link>
                            <p

                                onClick={handleSentOtp}
                            // class="login-header"
                            >
                                {/* <Link to="/dashboard"> */}
                                {
                                    loader == true ? '' :
                                        <input
                                            style={{ borderRadius: 50 }}
                                            type="submit"
                                            value="Send OTP"
                                        // class="login-header"
                                        />
                                }
                                {/* </Link> */}
                                {
                                    loader == true && <div style={{ marginLeft: '170px' }} >
                                        <Spinner animation="border" variant="Primary" />
                                    </div>
                                }
                            </p>
                        </div>
                    </div>}
                    {page === 2 &&
                        <div>
                            <div>
                                <h2 class="login-header" style={{ marginTop: 20 }}>Enter Sent OTP </h2>
                                <p>
                                    <input
                                        type="phone"
                                        placeholder="Enter OTP"
                                        name='otp'
                                        autoComplete="off"
                                        value={otpData.otp}
                                        onChange={handleOTPChange}
                                    />
                                    <span className="text-danger" >{otpDataError.otp}</span>
                                </p>
                                <Link to='/'>Login ?</Link>
                                <p

                                    onClick={handleVerifyOtp}
                                // class="login-header"
                                >
                                    {/* <Link to="/dashboard"> */}
                                    {
                                        loader == true ? '' :
                                            <input
                                                style={{ borderRadius: 50 }}
                                                type="submit"
                                                value="Verify OTP"
                                            // class="login-header"
                                            />
                                    }
                                    {/* </Link> */}
                                    {
                                        loader == true && <div style={{ marginLeft: '170px' }} >
                                            <Spinner animation="border" variant="Primary" />
                                        </div>
                                    }
                                </p>
                            </div>
                        </div>}
                    {page === 3 && <div>
                        <div>
                            <h2 class="login-header" style={{ marginTop: 20 }}>Enter New Password </h2>
                            <p>
                                <input
                                    type="text"
                                    placeholder="Enter New Password"
                                    name='password'
                                    autoComplete="off"
                                    value={password.password}
                                    onChange={handlePasswordChange}
                                />
                                <span className="text-danger" >{passwordError.password}</span>
                            </p>
                            <p>
                                <input
                                    type="text"
                                    placeholder="Re-enter New Password"
                                    name='confirmPassword'
                                    autoComplete="off"
                                    value={password.confirmPassword}
                                    onChange={handlePasswordChange}
                                />
                                <span className="text-danger" >{passwordError.confirmPassword}</span>
                            </p>
                            <p className='d-flex justify-content-center align-content-center'>
                            <Link to='/'>Login ?</Link>
                            </p>

                            <p

                                onClick={handleSubmit}
                            // class="login-header"
                            >
                                {/* <Link to="/dashboard"> */}
                                {
                                    loader == true ? '' :
                                        <input
                                            style={{ borderRadius: 50 }}
                                            type="submit"
                                            value="Submit"
                                        // class="login-header"
                                        />
                                }
                                {/* </Link> */}
                                {
                                    loader == true && <div style={{ marginLeft: '170px' }} >
                                        <Spinner animation="border" variant="Primary" />
                                    </div>
                                }
                            </p>
                        </div>
                    </div>}


                </div>
            </div>
        </div>
    )
}

export default ForgotPassword