import React, { useState, useEffect } from "react";
import SideBar from "../../dashboard/SideBar";
import FirstNavbar from "../../dashboard/FirstNavbar";
import { toast } from "react-toastify";

import {
  Card,
  Row,
  Col,
  Figure,
  Table,
  Button,
  Modal,
  Form,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import './mystore.css'

function MyStore() {
  const [profileModal, setProfileModal] = useState(false);
  const handleCloseModal = () => setProfileModal(false);
  const handleShowModal = (e, shop) => {
    e.target.value === "add" ? setAction("add") : setAction("edit");

    setProfileModal(true);
    if (e.target.value === "add") {
      setLogo("");
      setLogoui("");
      setGSTImage("");
      setGSTImageui("");
      setStoreDetails({
        name: "",
        // description: "",
        email: "",
        phone: "",
        bank: {
          bankName: "",
          accNumber: "",
          ifsc: "",
        },
        address: {
          city: "",
          zip: "",
        },
        gst: "",
      });
      setStoreDetailsError({
        name: "",
        // description: "",
        email: "",
        phone: "",
        bank: {
          bankName: "",
          accNumber: "",
          ifsc: "",
        },
        address: {
          city: "",
          zip: "",
        },
        gst: "",
      });
    } else {
      getEditData(shop);
      console.log(shop?._id);
      setStoreDetailsError({
        name: "",
        // description: "",
        email: "",
        phone: "",
        bank: {
          bankName: "",
          accNumber: "",
          ifsc: "",
        },
        address: {
          city: "",
          zip: "",
        },
        gst: "",
      });
    }
  };

  const [shopToUpdate, setShopToUpdate] = useState("");
  const [shopList, setShopList] = useState("");
  const [action, setAction] = useState("add");
  const [logo, setLogo] = useState("");
  const [logoui, setLogoui] = useState("");
  const [gstImage, setGSTImage] = useState("");
  const [gstImageui, setGSTImageui] = useState("");

  const [storeDetails, setStoreDetails] = useState({
    name: "",
    // description: "",
    email: "",
    phone: "",
    bank: {
      bankName: "",
      accNumber: "",
      ifsc: "",
    },
    address: {
      city: "",
      zip: "",
    },
    gst: "",
  });

  const [storeDetailsError, setStoreDetailsError] = useState({
    name: "",
    // description: "",
    email: "",
    phone: "",
    bank: {
      bankName: "",
      accNumber: "",
      ifsc: "",
    },
    address: {
      city: "",
      zip: "",
    },
    gst: "",
  });
  const getShopList = async () => {
    try {
      const response = await axios.get(
        "https://zelt-product.moshimoshi.cloud/shop/vendor",
        {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("accessToken"),
          },
        }
      );
      if (response.status === 200) {
        console.log(response.data.data);
        setShopList(response.data.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getShopList();
  }, []);

  const getEditData = async (shop) => {
    try {
      const res = await axios.get(
        `https://zelt-product.moshimoshi.cloud/shop/list/${shop._id}`,
        {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("accessToken"),
          },
        }
      );
      if (res.status === 200) {
        setStoreDetails(res.data.data);
        setGSTImageui(res.data.data.gstImage);
        setGSTImage(res.data.data.gstImage);
        console.log(gstImageui, "hel");
        setLogoui(res.data.data.logo);
        setLogo(res.data.data.logo);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleChange = ({ target: { name, value } }) => {
    setStoreDetails({ ...storeDetails, [name]: value });
    setStoreDetailsError({ ...storeDetailsError, [name]: "" });
  };

  const addNewStore = async (storeDetails) => {
    const handelValidationObject = handelValidation();
    // handelValidationObject(Object.keys(handelValidationObject).length);
    if (Object.keys(handelValidationObject).length > 0) {
      console.log("if working");
      setStoreDetailsError(handelValidationObject);
      console.log(storeDetailsError, "errors");
      console.log(storeDetails, "store ");
    } else {
      const formData = new FormData();
      formData.append("name", storeDetails.name);
      console.log(formData);
      // formData.append("description", storeDetails.description);
      formData.append("email", storeDetails.email);
      formData.append("phone", storeDetails.phone);
      formData.append("gst", storeDetails.gst);
      formData.append("address", JSON.stringify(storeDetails.address));
      formData.append("bank", JSON.stringify(storeDetails.bank));
      formData.append("logo", logo);
      formData.append("gstImage", gstImage);
      console.log(formData);
      const data = await axios
        .post(`https://zelt-product.moshimoshi.cloud/shop`, formData, {
          headers: {
            "x-access-token": localStorage.getItem("accessToken"),
          },
        })
        .catch((error) => {
          console.log("error ==>", error);
        });
      if (data.status === 200) {
        toast.success("New Store Added", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          theme: "light",
        });
        handleCloseModal();
        getShopList();
      }
    }
  };

  const editStore = async (storeDetails) => {
    const handelValidationObject = handelValidation();
    if (Object.keys(handelValidationObject).length > 0) {
      setStoreDetailsError(handelValidationObject);
    } else {
      console.log("else is working");
      const formData = new FormData();
      formData.append("name", storeDetails.name);
      // formData.append("description", storeDetails.description);
      formData.append("email", storeDetails.email);
      formData.append("phone", storeDetails.phone);
      formData.append("gst", storeDetails.gst);
      formData.append("address", JSON.stringify(storeDetails.address));
      formData.append("bank", JSON.stringify(storeDetails.bank));
      formData.append("logo", logo);
      console.log(formData.name);

      const data = await axios
        .patch(`https://zelt-product.moshimoshi.cloud/shop/${shopToUpdate}`, formData, {
          headers: {
            "x-access-token": localStorage.getItem("accessToken"),
          },
        })
        .catch((error) => {
          console.log("error ==>", error);
        });
      console.log("store updated  ===> ", data);
      if (data.status === 200) {
        toast.success(" Details updated ", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          theme: "light",
        });
        handleCloseModal();
        getShopList();
      }
    }
  };

  const handelValidation = () => {
    const newErrors = {};

    const { zip, city } = storeDetails.address;
    const { bankName, ifsc, accNumber } = storeDetails.bank;

    const { description, email, gst, name, owner, phone } = storeDetails;
    const emailRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    // const nameRegex = /^[a-zA-Z\s]{1,}[\.]{0,1}[a-zA-Z\s]{0,}$/;
    const accNumberRegex = /^\d{16}$/;
    const gstNumberRegex =
      /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
    const ifscRegex = /^[A-Za-z]{4}0[A-Z0-9a-z]{6}$/;
    // const phoneRegex = /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[789]\d{9}$/;
    if (name === "") {
      newErrors.name = "please enter name";
    }

    if (email === "") {
      newErrors.email = "please enter a valid email address";
    }
    if (accNumber === "") {
      newErrors.accNumber = "please enter a valid account number ";
    }

    if (bankName === "") {
      newErrors.bankName = "please enter a Bank Name";
    }
    // if (description === "") {
    //   newErrors.description = "please add description";
    // }

    if (gst === "") {
      newErrors.gst = "please enter a valid gst number";
    }
    if (ifsc === "") {
      newErrors.ifsc = "please enter a valid  ifsc code ";
    }
    if (owner === "") {
      newErrors.owner = "please enter a owner Name";
    }

    if (phone === '') {
      newErrors.phone = "please enter a valid phone number";
    }
    if (city === "") {
      newErrors.city = "please enter the city";
    }
    if (zip === "") {
      newErrors.zip = "please enter the zip";
    }
    if (logo === "") {
      newErrors.logo = "please upload store logo";
    }
    if (gstImage === "") {
      newErrors.gstImage = "please Upload GST certificate";
    }

    return newErrors;
  };

  return (
    <div>
      <div class="sidebar">
        <SideBar />
      </div>
      <div class="content">
        <div className="container">
          <FirstNavbar />
          <h3 className="headertext">Stores</h3>

          <div>
            <Card className="p-2" style={{ width: "100%", overflowX: "auto" }}>
              <Row>
                <Col md={3}>
                  <h3 className="headertext">My Stores</h3>
                </Col>
                <Col md={7}>
                  <div className="w-100 d-flex justify-content-end">
                  <Button
                 
                    variant="warning"
                    value="add"
                    onClick={(e) => handleShowModal(e)}
                  >
                    Add new Store
                  </Button>
                  </div>
                </Col>
              </Row>

              <Card
                className="p-2"
                style={{ width: "100%", overflowX: "auto" }}
              >
                {/* <hr /> */}
                <h3 className="text1">Available stores</h3>
                <Table striped bordered hover style={{ width: "100%" }}>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Store Image</th>
                      <th>Store Name</th>
                      <th>City</th>
                      <th>ZIP</th>
                      <th>Phone Number</th>
                      <th>Email</th>
                      {/* <th> Gst Image</th> */}
                      {/* <th>description</th> */}

                      <th>Edit</th>
                    </tr>
                  </thead>
                  <tbody>
                    {shopList &&
                      shopList.map((shop, i) => (
                        <tr key={i}>
                          <td>{i + 1}</td>
                          <td>
                            <Figure>
                              <Figure.Image
                                width={100}
                                height={100}
                                alt="Image"
                                src={shop.logo}
                              />
                            </Figure>
                          </td>
                          <td>{shop.name}</td>

                          <td>{shop.address?.city}</td>
                          <td>{shop.address?.zip}</td>
                          <td>{shop.phone}</td>
                          <td>{shop.email}</td>
                          
                          {/* <td>
                            {" "}
                            <Figure>
                              <Figure.Image
                                width={100}
                                height={100}
                                alt="GST"
                                src={shop.gstImage}
                              />
                            </Figure>
                          </td> */}
                          {/* <td>{shop.description}</td> */}

                          <td>
                            <FontAwesomeIcon
                              icon={faEdit}
                              className="editIcon"
                              value="edit"
                              onClick={(e) => {
                                console.log(action);
                                setShopToUpdate(shop._id);
                                console.log();
                                handleShowModal(e, shop);
                              }}
                            />
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </Card>
            </Card>
          </div>
        </div>
      </div>
      <Modal
        show={profileModal}
        onHide={handleCloseModal}
        backdrop="static"
        keyboard={false}
        centered
        size="lg"
      >
        <h4 className="headertext text-center">Add/Edit Store Details</h4>
        <Modal.Body>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Store Name</Form.Label>
                <Form.Control
                  maxLength={50}
                  type="text"
                  placeholder="Enter Store Name"
                  size="sm"
                  name="name"
                  onChange={handleChange}
                  autoComplete="off"
                  className="mb-3"
                  value={storeDetails?.name}
                />

                <span className="text-danger">{storeDetailsError?.name}</span>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>GST Number</Form.Label>
                <Form.Control
                  maxLength={10}
                  type="text"
                  placeholder="Enter GST Number"
                  size="sm"
                  name="gst"
                  onChange={handleChange}
                  autoComplete="off"
                  className="mb-3"
                  value={storeDetails?.gst}
                />
                <span className="text-danger">{storeDetailsError?.gst}</span>
              </Form.Group>
            </Col>
            {/* kdk/ */}
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Phone Number</Form.Label>
                <Form.Control
    type="text"
    placeholder="Enter Phone Number"
    size="sm"
    name="phone"
    value={storeDetails?.phone}
    onChange={(e) => {
        const input = e.target.value;
        const regex = /^[0-9]{0,10}$/; // Allow only numbers and up to 10 digits
        if (regex.test(input)) {
            setStoreDetails({
                ...storeDetails,
                phone: input,
            });
        }
    }}
    autoComplete="off"
    className="mb-3 number-input"
/>
                <span className="text-danger">{storeDetailsError?.phone}</span>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Bank Name</Form.Label>
                <Form.Control
                  maxLength={50}
                  type="text"
                  placeholder="Enter Bank Name"
                  size="sm"
                  name="bankName"
                  value={storeDetails.bank?.bankName}
                  onChange={(e) => {
                    setStoreDetails({
                      ...storeDetails,
                      bank: {
                        ...storeDetails.bank,
                        bankName: e.target.value,
                      },
                    });
                    setStoreDetailsError({
                      ...storeDetailsError,
                      bank: {
                        accNumber: "",
                        bankName: "",
                        ifsc: "",
                      },
                    });
                  }}
                  autoComplete="off"
                  className="mb-3"
                />
                <span className="text-danger">
                  {storeDetailsError?.bankName}
                </span>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Email Id</Form.Label>
                <Form.Control
                  maxLength={50}
                  type="email"
                  placeholder="Enter Email Id"
                  size="sm"
                  name="email"
                  onChange={handleChange}
                  autoComplete="off"
                  className="mb-3"
                  value={storeDetails?.email}
                />
                <span className="text-danger">{storeDetailsError?.email}</span>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Account Number</Form.Label>
                <Form.Control
                  maxLength={15}
                  type="number"
                  placeholder="Enter Account Number"
                  size="sm"
                  name="accNumber"
                  autoComplete="off"
                  className="mb-3 number-input"
                  value={storeDetails.bank?.accNumber}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                
                    if (/^\d{0,17}$/.test(inputValue)) {
                      setStoreDetails({
                        ...storeDetails,
                        bank: {
                          ...storeDetails.bank,
                          accNumber: inputValue,
                        },
                      });
                      setStoreDetailsError({
                        ...storeDetailsError,
                        bank: {
                          accNumber: "",
                          bankName: "",
                          ifsc: "",
                        },
                      });
                    }
                  }}
                />
                <span className="text-danger">
                  {storeDetailsError?.accNumber}
                </span>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>City</Form.Label>
                <Form.Control
                  maxLength={50}
                  type="text"
                  placeholder="Enter City"
                  size="sm"
                  name="city"
                  value={storeDetails.address?.city}
                  onChange={(e) => {
                    setStoreDetails({
                      ...storeDetails,
                      address: {
                        ...storeDetails.address,
                        city: e.target.value,
                      },
                    });
                    setStoreDetailsError({
                      ...setStoreDetailsError,
                      address: {
                        city: "",
                        zip: "",
                      },
                    });
                  }}
                  autoComplete="off"
                  className="mb-3"
                />
                <span className="text-danger">{storeDetailsError?.city}</span>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Ifsc Code</Form.Label>
                <Form.Control
                  maxLength={11}
                  type="text"
                  placeholder="Enter IFSC Code"
                  size="sm"
                  name="ifsc"
                  value={storeDetails.bank?.ifsc}
                  onChange={(e) => {
                    setStoreDetails({
                      ...storeDetails,
                      bank: {
                        ...storeDetails.bank,
                        ifsc: e.target.value,
                      },
                    });
                    setStoreDetailsError({
                      ...storeDetailsError,
                      bank: {
                        accNumber: "",
                        bankName: "",
                        ifsc: "",
                      },
                    });
                  }}
                  autoComplete="off"
                  className="mb-3"
                />
                <span className="text-danger">{storeDetailsError?.ifsc}</span>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>ZIPCODE</Form.Label>
                <Form.Control
    type="text"
    placeholder="Enter ZIP"
    size="sm"
    name="zip"
    value={storeDetails.address?.zip}
    onChange={(e) => {
        const input = e.target.value;
        const regex = /^[0-9]{0,6}$/; // Allow only numbers and up to 6 digits
        if (regex.test(input)) {
            setStoreDetails({
                ...storeDetails,
                address: {
                    ...storeDetails.address,
                    zip: input,
                },
            });
            setStoreDetailsError({
                ...storeDetailsError,
                address: {
                    city: "",
                    zip: "",
                },
            });
        }
    }}
    autoComplete="off"
    className="mb-3 number-input"
/>
                <span className="text-danger">{storeDetailsError?.zip}</span>
              </Form.Group>
            </Col>
            {/* <Col>
              <Form.Group className="mb-3">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  type="text"
                  name="description"
                  placeholder="Enter description"
                  onChange={handleChange}
                  autoComplete="off"
                  maxLength={50}
                  size="sm"
                  className="mb-3"
                  height="100px"
                  value={storeDetails?.description}
                />
                <span className="text-danger">
                  {storeDetailsError?.description}
                </span>
              </Form.Group>
            </Col> */}
          </Row>
          <Row></Row>

          <Row>
            <Row>
              {gstImage === "" ? null : (
                <Figure>
                  <Figure.Image
                    width={100}
                    height={100}
                    alt="171x1801"
                    src={gstImageui}
                  />
                </Figure>
              )}
            </Row>

            <Col>
              <Form.Group className="mb-3">
                <Form.Label>Select GST Image</Form.Label>
                <Form.Control
                  type="file"
                  placeholder="Select GST Image"
                  className="mb-3"
                  // className="w-50"
                  name="gstImage"
                  onChange={(e) => {
                    setGSTImage(e.target.files[0]);
                    setGSTImageui(URL.createObjectURL(e.target.files[0]));
                  }}
                  autoComplete="off"
                />
                <span className="text-danger">
                  {storeDetailsError?.gstImage}
                </span>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Row>
              {logo === "" ? null : (
                <Figure>
                  <Figure.Image
                    width={100}
                    height={100}
                    alt="171x180"
                    src={logoui}
                  // onChange={}
                  />
                </Figure>
              )}
            </Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>Select Store Logo</Form.Label>
                <Form.Control
                  type="file"
                  placeholder="Store Logo"
                  className="mb-3"
                  // className="w-50"
                  name="logo"
                  onChange={(e) => {
                    setLogo(e.target.files[0]);
                    setLogoui(URL.createObjectURL(e.target.files[0]));
                  }}
                  autoComplete="off"
                />
                <span className="text-danger">{storeDetailsError?.logo}</span>
              </Form.Group>
            </Col>
          </Row>
          {/* <Row>
            <Col md={6}>
              <Button variant="warning" onClick={editLogo}>Update</Button>
            </Col>
          </Row> */}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleCloseModal} variant="secondary">
            Cancel
          </Button>
          <Button
            variant="warning"
            onClick={() => {
              console.log("action ====>", action);
              action === "add"
                ? addNewStore({ ...storeDetails })
                : editStore({ ...storeDetails });
            }}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default MyStore;
