import React, { useEffect, useState } from 'react'
import SideBar from '../../dashboard/SideBar'
import FirstNavbar from '../../dashboard/FirstNavbar'
import { Card, Row, Col, Figure, Table, Button, Modal, Form, InputGroup } from 'react-bootstrap'
import Plot from 'react-plotly.js'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faEye } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import axios from 'axios'
import { Chart } from "react-google-charts";


const image = require('../../../assets/imagesCustomer/image.png');

function Stats() {
  const [jewelleryModal, setJewelleryModal] = useState(false)
  const [coinModal, setCoinModal] = useState(false)
  const [selection, setSelection] = useState('1')
  const [stats, setStats] = useState({ soldData: { product: [], scheme: [] }, brochureReqs: [], customers: [] });
  const [shopId] = useState(localStorage.getItem('shopId'));
  const navigate = useNavigate();
  const [brochureReqsData, setBrochureReqsData] = useState([])
  const handleCloseModal = () => setJewelleryModal(false)
  const handleShowModal = () => setJewelleryModal(true)

  const handleCloseModal1 = () => setCoinModal(false)
  const handleShowModal1 = () => setCoinModal(true)

  const [productSold, setProductSold] = useState([]);


  const getStats = async () => {
    try {
      const data = await axios.get(`https://zelt-product.moshimoshi.cloud/shop/stats/` + localStorage.getItem('shopId'), {
        headers: { "x-access-Token": localStorage.getItem("accessToken") }
      });
  
      console.log("getShopStats ===>", data.data.data.soldData)
      setStats(data.data.data)
      setProductSold(data.data.data.soldData.product)
      // setBrochureReqsData(data.data.data.brochureReqs);
      setBrochureReqsData(data.data.data.brochureReqs)
    } catch (error) {
      console.log('error ==>', error);
    }
  }
  console.log(brochureReqsData.length
    ,"statsstatsstats")
  const [data, setData] = useState([]);

  useEffect(() => {
    if (productSold.length > 0) {
      const formattedData = productSold.map(item => {
        const dateParts = item.date.split('/');
        const formattedDate = `${dateParts[1]}/${dateParts[2]}/${dateParts[0].substring(2)}`;
        return [formattedDate, item.amount];
      });

      const chartData = [["Year", "Sales"], ...formattedData];
      setData(chartData);

      // Now you can use chartData for your chart
      console.log(chartData, 'chartData');
    }
  }, [productSold]);


  // const data = [
  //   ["Year", "Sales"],
  //   ["01/01/21", 1000],
  //   ["01/02/21", 1200],
  //   ["01/04/21", 660],
  //   ["01/05/21", 1030],
  // ];

  const options = {
    title: "Chart showing Jewellery sold in last 7 days",
    curveType: "function",
    legend: { position: "bottom" },
    chartEvents: [
      {
        eventName: "ready",
        callback: ({ chartWrapper }) => {
          const chart = chartWrapper.getChart();
          console.log("Callback triggered");
          chart.container.style.backgroundColor = "lightgrey"; // Set your desired background color
        },
      },
    ],
  };
  const option = {
    title: "Chart showing Schemes sold in last 7 days",
    curveType: "function",
    legend: { position: "bottom" },
    chartEvents: [
      {
        eventName: "ready",
        callback: ({ chartWrapper }) => {
          const chart = chartWrapper.getChart();
          console.log("Callback triggered");
          chart.container.style.backgroundColor = "lightgrey"; // Set your desired background color
        },
      },
    ],
  };
  useEffect(() => {
    getStats();
  }, [shopId])


  // const getShopStats = async () => {
  //   const data = await axios.get(`https://zelt-product.moshimoshi.cloud/product/stats?shopId=${shopId}`, {
  //     headers: { "x-access-Token": localStorage.getItem("accessToken") }
  //   }).catch((error) => {
  //     console.log('error ==>', error);
  //   })
  //   setProductSold(data)
  // }

  // console.log(productSold,'k');

  // useEffect(() => {
  //   getShopStats()
  // }, [shopId])

  return (
    <div>

      {/* <FirstNavbar /> */}
      <h3 className='headertext'>Store Statistics</h3>
      {/* <InputGroup className="w-25">
        <InputGroup.Text>Select Shop</InputGroup.Text>
        <Form.Select
          aria-label="Select Shops"
          size={"sm"}
          onChange={(e) => {
            localStorage.setItem("shopId", e.target.value)
            getStats()
          }}
          defaultValue={
            localStorage.getItem('userDetails') &&
            JSON.parse(localStorage.getItem('userDetails')).data?.shops?.[0]?._id
          }

        >
       
          {
            localStorage.getItem('userDetails') && JSON.parse(localStorage.getItem('userDetails')).data?.shops?.map(shop => (
              <option value={shop._id}>{shop.name}</option>
            ))
          }
        </Form.Select>
      </InputGroup> */}
      <div className='' style={{ marginTop: 15 }}>
        <Card className='p-2 '>
          <Row>
            <Col md={4}>
              <h3 className='headertext'>Jewellery Sold</h3>
            </Col>
            <Col md={4}>
              <Button onClick={() => navigate('/JewellerySold')} variant="warning">View More</Button>
            </Col>
          </Row>
          <Row>
            <Col md={9}>
              <Chart
                chartType="LineChart"
                width="100%"
                height="400px"
                data={data}
                options={options}
              />
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <h3 className='headertext'>Schemes Sold</h3>
            </Col>
            <Col md={4}>
              <Button onClick={() => navigate('/SchemesSold')} variant="warning">View More</Button>
            </Col>
          </Row>
          <Row>
            <Col md={9}>
              <Chart
                chartType="LineChart"
                width="100%"
                height="400px"
                data={data}
                options={option}
              />
            </Col>
          </Row>
          <hr />
          <Row>
            <Col md={4}>
              <h3 className='headertext'>Brochure Requests</h3>
            </Col>
            <Col md={3}>
              <Button onClick={() => navigate('/BrochureRequest')} variant="warning">View More</Button>
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <div style={{ borderRadius: 50, height: 100, width: 100, border: '2px solid #BE783B', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                <h1 style={{}}>
                  {brochureReqsData?.length}
                </h1>
              </div>
            </Col>
          </Row>
          <hr />
          <Card className='p-2'>
            <h3 className='text1'>Customer List</h3>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Customer Name</th>
                  <th>Phone Number</th>
                  <th>E-mail Id</th>
                  <th>View</th>
                </tr>
              </thead>
              <tbody>
                {
                  stats.customers && stats.customers.map((c, i) => (
                    <tr key={i}>
                      <td>{i + 1}</td>
                      <td>{c.name}</td>
                      <td>{c.phone}</td>
                      <td>{c.email}</td>
                      <td>
                        <FontAwesomeIcon
                          onClick={() => navigate('/CustomerDetail?id=' + c._id)}
                          icon={faEye} className="editIcon"
                        />
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </Table>
          </Card>
        </Card>
      </div>
      <Modal
        show={jewelleryModal}
        onHide={handleCloseModal}
        backdrop="static"
        keyboard={false}
        centered
        size="lg"
      >
        <h4 className='headertext text-center'>Add/Edit Admin Details</h4>
        <Modal.Body>
          <Row>
            <Col md={6}>
              <Form.Check
                inline
                label="Gold"
                name="group1"
                type="radio"
              />
              <Form.Check
                inline
                label="Silver"
                name="group1"
                type="radio"
              />
              <Form.Check
                inline
                label="Platinum"
                name="group1"
                type="radio"
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  maxLength={50}
                  type="text"
                  placeholder="Enter Name"
                  size="sm"
                  name='Name'
                  onChange={(e) => e.target.value}
                  autoComplete='off'
                  className='mb-3'
                />
                <span className="text-danger">{ }</span>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Purity</Form.Label>
                <Form.Control
                  maxLength={50}
                  type="text"
                  placeholder="Enter Purity"
                  size="sm"
                  name='Name'
                  onChange={(e) => e.target.value}
                  autoComplete='off'
                  className='mb-3'
                />
                <span className="text-danger">{ }</span>
              </Form.Group>


            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Weight</Form.Label>
                <Form.Control
                  maxLength={50}
                  type="text"
                  placeholder="Enter Weight"
                  size="sm"
                  name='Name'
                  onChange={(e) => e.target.value}
                  autoComplete='off'
                  className='mb-3'
                />
                <span className="text-danger">{ }</span>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Select Stores</Form.Label>
                <div className="leftedge d-flex justify-content-space">
                  <Form.Select
                    aria-label="Default select example"
                    size={"sm"}
                    className="selectsizesmall"
                    onChange={() => { }}
                  >
                    {/* <option >Select Store</option> */}
                    <option value="1">hari</option>
                    <option value="2">gopal</option>
                    <option value="3">mani</option>
                    <option value="4">prema</option>
                    <option value="5">hema</option>
                    <option value="6">Ragu</option>
                    <option value="7">ram</option>
                  </Form.Select>
                </div>
                <span className="text-danger">{ }</span>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Select Payment Option</Form.Label>
                <div className="leftedge d-flex justify-content-space">
                  <Form.Select
                    aria-label="Default select Payment"
                    size={"sm"}
                    className="selectsizesmall"
                    onChange={() => { }}
                  >
                    {/* <option >Select Payment Option</option> */}
                    <option value="1">Offline</option>
                    <option value="2">Online</option>
                  </Form.Select>
                </div>
                <span className="text-danger">{ }</span>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label></Form.Label>
                <Form.Check
                  type="switch"
                  id="custom-switch"
                  label="Availability"
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>Add Product Image</Form.Label>
                <Form.Control
                  type="file"
                  placeholder="Product Image"
                  className="w-50"
                  name="image"
                  autoComplete="off"
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Button variant="warning">Update Image</Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>Add Product Video</Form.Label>
                <Form.Control
                  type="file"
                  placeholder="Product Video"
                  className="w-50"
                  name="image"
                  autoComplete="off"
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Button variant="warning">Update Video</Button>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleCloseModal} variant="secondary">
            Cancel
          </Button>
          <Button variant="warning">
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={coinModal}
        onHide={handleCloseModal1}
        backdrop="static"
        keyboard={false}
        centered
        size="lg"
      >
        <h4 className='headertext text-center'>Add/Edit Bar/Coins</h4>
        <Modal.Body>
          <Row>
            <Col md={6}>
              <Form.Check
                inline
                label="Bar"
                name="group1"
                type="radio"
              />
              <Form.Check
                inline
                label="Coin"
                name="group1"
                type="radio"
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  maxLength={50}
                  type="text"
                  placeholder="Enter Name"
                  size="sm"
                  name='Name'
                  onChange={(e) => e.target.value}
                  autoComplete='off'
                  className='mb-3'
                />
                <span className="text-danger">{ }</span>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Purity</Form.Label>
                <Form.Control
                  maxLength={50}
                  type="text"
                  placeholder="Enter Purity"
                  size="sm"
                  name='Name'
                  onChange={(e) => e.target.value}
                  autoComplete='off'
                  className='mb-3'
                />
                <span className="text-danger">{ }</span>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Weight</Form.Label>
                <Form.Control
                  maxLength={50}
                  type="text"
                  placeholder="Enter Weight"
                  size="sm"
                  name='Name'
                  onChange={(e) => e.target.value}
                  autoComplete='off'
                  className='mb-3'
                />
                <span className="text-danger">{ }</span>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Select Store</Form.Label>
                <div className="leftedge d-flex justify-content-space">
                  <Form.Select
                    aria-label="Default select example"
                    size={"sm"}
                    className="selectsizesmall"
                    onChange={() => { }}
                  >
                    {/* <option >Select Store</option> */}
                    <option value="1">Sri Vinayaka Jewellers, Mumbai</option>
                    <option value="2">Sri Vinayaka Jewellers, Bengalore</option>
                    <option value="3">Sri Vinayaka Jewellers, Jay Nagar</option>
                    <option value="4">Sri Vinayaka Jewellers, JP Nagar</option>
                    <option value="5">Sri Vinayaka Jewellers, Delhi</option>
                    <option value="6">Sri Vinayaka Jewellers, KR Market</option>
                    <option value="7">Sri Vinayaka Jewellers, Mangalore</option>
                  </Form.Select>
                </div>
                <span className="text-danger">{ }</span>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Enter Model</Form.Label>
                <Form.Control
                  maxLength={50}
                  type="text"
                  placeholder="Enter Model"
                  size="sm"
                  name='Name'
                  onChange={(e) => e.target.value}
                  autoComplete='off'
                  className='mb-3'
                />
                <span className="text-danger">{ }</span>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Enter Packaging</Form.Label>
                <Form.Control
                  maxLength={50}
                  type="text"
                  placeholder="Enter Packaging"
                  size="sm"
                  name='Name'
                  onChange={(e) => e.target.value}
                  autoComplete='off'
                  className='mb-3'
                />
                <span className="text-danger">{ }</span>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Select Payment Option</Form.Label>
                <div className="leftedge d-flex justify-content-space">
                  <Form.Select
                    aria-label="Default select Payment"
                    size={"sm"}
                    className="selectsizesmall"
                    onChange={() => { }}
                  >
                    {/* <option >Select Payment Option</option> */}
                    <option value="1">Offline</option>
                    <option value="2">Online</option>
                  </Form.Select>
                </div>
                <span className="text-danger">{ }</span>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>Add Product Image</Form.Label>
                <Form.Control
                  type="file"
                  placeholder="Admin Image"
                  className="w-50"
                  name="image"
                  autoComplete="off"
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Button variant="warning">Update</Button>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleCloseModal1} variant="secondary">
            Cancel
          </Button>
          <Button variant="warning">
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </div >
  )
}

export default Stats