import React, { useEffect, useState } from 'react'
import { BsSpeedometer } from 'react-icons/bs'
import { CgProfile } from 'react-icons/cg'
import { IoStorefrontOutline, IoDiamondOutline, IoStatsChart } from 'react-icons/io5'
import { MdOutlineAdminPanelSettings, MdOutlineSchema } from 'react-icons/md'
import { GiBookmarklet, GiVerticalBanner } from 'react-icons/gi'
import { RiAdvertisementLine } from 'react-icons/ri'
import {
  Accordion,
  Button,
  Card,
  Container,
  Form,
  FormControl,
  ListGroup,
  Nav,
  Navbar,
  NavDropdown,
  NavLink,
  Offcanvas,
} from "react-bootstrap";
import { Navigate, Navigation, useLocation, useNavigate } from "react-router-dom";
import {
  AiFillAppstore,
  AiFillDatabase,
  AiOutlineRise,
  AiFillSignal,
  AiOutlineShoppingCart,
} from "react-icons/ai";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import './index.css'
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
// import baseURL from "../../Services/Url";
import { AgGridReact } from "ag-grid-react";
import logozelt from '../../assets/imagesCustomer/logozelt.png'



function SideBar({ Navigation }) {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(true)
  const [counter, setCounter] = useState(0)
  const [dashboardData, setDashboardData] = useState({});
  const [liveRate, setLiveRate] = useState("");


  const [userDetails, setUserDetails] = useState();

  const handleClick = (e) => {

    // console.log(e);
    Navigation.navigate(`/list_out`, {
      data: e
    })
  }
  const liveRateGet = async () => {
    try {
      const response = await axios({
        method: "GET",
        url: "https://zelt-cart.moshimoshi.cloud/cart/live-rate",
      });

      console.log("live rate data", response?.data);
      setLiveRate(response?.data?.data["22kt"]);

      //else {}
    } catch (error) {
      console.log("live rate error ====> ", error);
    }
  };
  const getUserDetails = async () => {
    try {
      const response = await axios.get("https://zelt-auth.moshimoshi.cloud/user", {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("accessToken"),
        },
      });
      if (response.status === 200) {
        setUserDetails(response.data.data);
        // setEditUserDetails(response.data.data);

        console.log(response.data.data, "hello");
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(()=>{
    getUserDetails()
  },[])





  const VendorScreens = [{
    path: '/vendorDashboard',
    name: 'Dashboard',
    icon: <BsSpeedometer className="icons" />
  },
  {
    path: '/vendorProfile',
    name: 'Profile',
    icon: <CgProfile className='icons' />
  },
  {
    path: '/vendorStore',
    name: 'My Store',
    icon: <IoStorefrontOutline className='icons' />
  },
  {
    path: '/request',
    name: 'Request',
    icon: <MdOutlineAdminPanelSettings className='icons' />
  },
  {
    path: '/vendorProducts',
    name: 'Products',
    icon: <IoDiamondOutline className='icons' />
  },
  {
    path: '/vendorScheme',
    name: ' Scheme',
    icon: <MdOutlineSchema className='icons' />
  },
  // {
  //   path: '/vendorStats',
  //   name: 'Stats',
  //   icon : <IoStatsChart className='icons'/>
  // },
  // {
  //   path: '/customer',
  //   name: 'Customer',
  //   icon: <IoStatsChart className='icons' />
  // },
  {
    path: '/vendorBrochure',
    name: 'Brochure',
    icon: <GiBookmarklet className='icons' />
  },
  {
    path: '/AddBanner',
    name: 'Add Banner',
    icon: <GiVerticalBanner className='icons' />
  },
  {
    path: '/AddAdvertisement',
    name: 'Promote Product',
    icon: <RiAdvertisementLine className='icons' />
  },
  ]

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    liveRateGet()
  }, [])
  return (
    <>
      <div className={`sidebar${isOpen ? ' open' : ''}`}>
        <div className="sidebar-header d-flex align-items-center">
          <img src={logozelt} width="25%" height="20%" className="m-0" alt="logo" />
          <div className='w-100 m-2'>
            <h1 className='fs-2'>Zelt</h1>
          </div>

        </div>
        <div className='w-100 '>
          <div className='d-flex p-3' style={{ backgroundColor: '#dceaf8' }} >
            <img src={userDetails?.image} width="25%" height="20%" className="m-1" />

            <div>
              <h3 className='fs-6 m-1 fw-bold'>  {userDetails?.name}</h3>
              <h3 className=' m-1 fw-bold' style={{ fontSize: '15px' }}>{userDetails?.email}</h3>
            </div>
          </div>
        </div>
        <div className="sidebar-content mt-4">
          {VendorScreens.map((nav, i) => (
            <p key={i} className={`cursor fs-6 pt-2 pb-1 px-2 m-0 fw-bold ${location.pathname === nav.path ? 'active' : ''}`}>
              <Link to={nav?.path} className="p-1">
                {nav?.icon} {nav?.name}
              </Link>
            </p>
          ))}
        </div>
        <div className='d-flex justify-content-center mt-5 align-content-center' style={{ backgroundColor: '#d9d6a9' }}>
          <div className='mt-2'>
            <h1 className='fs-6 fw-bold w-100 m-1'>Live Rate</h1>
            <p className='fw-bold text-danger w-100 '>{liveRate}</p>
          </div>
        </div>
      </div>
    </>
  );
}
// }

export default SideBar;
